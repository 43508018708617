export default [
    [
        {
            col: 4,
            name: "campanha_nome",
            value: "",
            type: "text",
            placeholder: "Ex.: Campanha de natal",
            label: "Nome da campanha",
            required: true
        },
        {
            col: 4,
            name: "campanha_lista",
            value: "",
            type: "select",
            options: [],
            placeholder: "Ex: Contato 1",
            label: "Lista de contatos",
            readonly: true
        },
        {

            // @todo Receber notificação de conversão por: ( Email / WhatsApp / Nenhum )
            col: 4,
            name: "campanha_notificacao",
            value: "",
            type: "text",
            placeholder: "Ex.: 554195834666",
            label: "WhatsApp para notificação",
            required: true
        }
    ],
    [
        {
            col: 3,
            name: "campanha_agendamento",
            value: "",
            type: "date",
            placeholder: "01/05/2023",
            label: "Data de início",
            required: true
        },
        {
            col: 3,
            name: "campanha_hora_inicio",
            value: "",
            type: "time",
            label: "Hora de início",
            required: true
        },
        {
            col: 3,
            name: "campanha_hora_fim",
            value: "",
            type: "time",
            label: "Hora do fim",
            required: true,
            validateFunc: (val: any, item: any, all: any) => {
                if (val < all['campanha_hora_inicio']){
                    return "Hora final menor que inicial";
                }
                if (val === all['campanha_hora_inicio']){
                    return "Hora inicial e final devem ser diferentes";
                }
                return true;
            }
        },
        {
            col: 3,
            name: "intervalo_notificacao",
            value: "",
            type: "number",
            placeholder: "Ex.: 5",
            label: "Intervalo entre envios (minutos)",
            validateFunc: (val: any) => {
                const minutes = parseInt(val);
                if(isNaN(minutes)){
                    return "Informe um valor numérico"
                }
                if(minutes > 120){
                    return "O intervalo máximo é de 2 horas (120 min)"
                }
                if(minutes < 1){
                    return "O intervalo minimo nao pode ser negativo ou 0"
                }
                return true
            }
        }
    ],
    [
        {
            col: 12,
            name: "campanha_titulo",
            value: "",
            type: "text",
            placeholder: "Ex.: Aumente suas vendas com WhatsApp Marketing",
            label: "Título da mensagem",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "campanha_mensagem",
            value: "",
            type: "textarea",
            placeholder: "Ex: Prospecte clientes em massa enviando mensagens diretamente no WhatsApp deles",
            label: "Corpo da mensagem",
            required: true,
            minRows     : 3,
        }
    ],
    [
        {
            col: 12,
            name: "campanha_btn_acao",
            value: "",
            type: "text",
            placeholder: "Ex.: Saiba Mais",
            label: "Texto do Botão",
            required: true
        }
    ],
    [
        {
            col: 12,
            name: "campanha_mensagem_agradecimento",
            value: "",
            type: "textarea",
            placeholder: "Ex: Obrigado! Logo entraremos em contato",
            label: "Mensagem de agradecimento.",
            required: true,
            minRows: 3,
        }
    ]
]