<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg10 xl10">
      <va-card-content>
        <monta-listagem
          :table="table"
          @refresh="(loader) => refresh(this, loader, this.idUser)"
        >
          <template #cell(bot_public_id)="{ source: bot_public_id }">
            #{{bot_public_id}}
          </template>
          <template #cell(bot_connected)="{ source: is_online }">
            <va-chip
              v-if="is_online"
              :color="'success'"
            >
              Conectado
            </va-chip>
            <va-chip
              v-else
              :color="'danger'"
            >
              Desconectado
            </va-chip>
          </template>
          <template #cell(bot_type)="{ source: bot_type }">
            <div v-if="bot_type === 'RECEPTIVE'">
              Receptivo
            </div>
            <div v-if="bot_type === 'CAMPAIGN'">
              Campanhas
            </div>
            <div v-if="bot_type === 'CHECK'">
              Checagem
            </div>
          </template>
        </monta-listagem>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import table from "./UserBotsTable";
import {refresh} from "./EditarBotsFunctions";

export default {
  name: "EditarBots",
  data() {
    return {
      table,
      idUser: this.$route.params.id
    }
  },
  methods: {
    refresh
  }
}
</script>

<style scoped>

</style>