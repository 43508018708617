import {BotInterface} from "./UserBotsInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any, loader: boolean ,idUser: number): Promise<void>
{
    const response = await FetchWs('user/get-all-bots/' + idUser);
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertBots(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertBots(objs: BotInterface[]): BotInterface[]
{
    objs.forEach((value:BotInterface,key:number) => {
        objs[key].detalhes = value.bot_public_id
    })
    return objs;
}
