<template>
  <div class="row justify-center align-center">
    <va-card class="flex xs12 sm12 md12 lg8 xl8">
      <va-card-content>
        <monta-form v-if="formCarregado" :key="formCarregado" :form="form" @complete="(data)=>submitFormUpdate(data, this)"/>
      </va-card-content>
    </va-card>
  </div>
</template>

<script>
import {
  carregaDadosParaFormUpdate,
  getCamp,
  submitFormUpdate
} from "@/pages/global/campaigns/edit/EditCamFunctions";

export default {
  name: "EditCam",
  props:['id'],
  async mounted() {
    this.carregaDadosParaFormUpdate(this)
    await getCamp(this.idCamp);
  },
  data() {
    return {
      form: {},
      formCarregado: 0,
      idCamp: this.$route.params.id
    }
  },
  methods:{
    carregaDadosParaFormUpdate,
    submitFormUpdate
  },
}
</script>

<style scoped>

</style>