import {GruposInterfaces} from "./GruposInterfaces";
import {FetchWs} from "@/functions/FetchWs";

export async function refresh(vue: any): Promise<void>
{
    const response = await FetchWs('grupo/get-all');
    if(response && response.success && Array.isArray(response.data)){
        vue.table.items = assertList(response.data);
    } else {
        vue.table.items = [];
    }
}

function assertList(objs: GruposInterfaces[]): GruposInterfaces[]
{
    objs.forEach((value:GruposInterfaces,key:number) => {
        objs[key].edit_id = value.grp_id
    })
    return objs;
}

export async function editPerms(id: number, vue: any): Promise<void>
{
    await vue.$router.push({name: 'painel-admin-groups-perms', params:{id: id}});
}

export async function deleteGrupo(id: number, vue:any): Promise<void>
{
    await FetchWs('grupo/delete/' + id, 'GET');
    vue.$router.push({path: '/'});
}

