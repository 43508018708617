import {FetchWs} from "@/functions/FetchWs";
import FormStruct from "./EditCamFormStruct";

export async function getCamp(id: number): Promise<any>
{
    const response = await FetchWs('campaign/campaign-details/' +id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

async function getlistName(id: number): Promise<any>
{
    const response = await FetchWs('list/list-details/' +id);
    if(response && response.success){
        return response.data
    } else {
        return false;
    }
}

export async function carregaDadosParaFormUpdate(vue: any)
{
    const camp = await getCamp(vue.idCamp)
    const list = await getlistName(camp.lista)
    const struct = FormStruct

    //1 row
    struct[0][0].value = camp.name
    struct[0][1].value = list.name
    struct[0][2].value = camp.whats_notification
    //2 row
    struct[1][0].value = camp.initial_date
    struct[1][1].value = camp.initial_hour
    struct[1][2].value = camp.final_hour
    struct[1][3].value = camp.interval
    //3 row
    struct[2][0].value = camp.title
    //4 row
    struct[3][0].value = camp.message
    //5 row
    struct[4][0].value = camp.btn_action
    //6 row
    struct[5][0].value = camp.thanks_message

    vue.form.struct = struct
    vue.formCarregado++
}

export async function submitFormUpdate(data: any, vue: any)
{
    const body = new FormData();
    body.append("cam_id", vue.idCamp);
    body.append("name", data.campanha_nome);
    body.append("title", data.campanha_titulo);
    body.append("thanks_message", data.campanha_mensagem_agradecimento);
    body.append("message", data.campanha_mensagem);
    body.append("btn_action_text", data.campanha_btn_acao);
    body.append("notification_destination", data.campanha_notificacao);
    body.append("notification_interval", data.intervalo_notificacao);
    body.append("initial_date", data.campanha_agendamento);
    body.append("initial_notification_hour", data.campanha_hora_inicio);
    body.append("final_notification_hour", data.campanha_hora_fim);

    const response = await FetchWs('campaign/update/' + vue.idCamp, 'POST',body);

    if(response?.success) {
        vue.$router.back()
    }
}